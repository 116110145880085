.wrapper {

    height: 100vh;

    .main-panel{
        height: 100%;
        width: 100%;
        display: flex;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
        .sidebar{
            height: 100%;
            width: auto;
            min-height: calc(99vh - 10px);
            min-width: 300px;
            background-color: $primary-meco-blue;

            .sidebar-wrapper {
                height: 100%;
                width: 100%;
                
                .hht-logo{
                    height: auto;
                    width: auto;
                    // padding: 60px 85px 0px 85px;
                    padding-top: 48px;
                    text-align: center;
                    margin-bottom: 48px;
                    img {
                        height: auto;
                        width: auto;
                    }
                }

                .nav {
                    display: block;
                    margin: 0px 16px 0px 16px;
                    cursor: pointer;

                    li {
                        height: 64px;
                        padding: 16px 0 0 32px;
                        
                        .link{
                            outline: none;
                        }

                        &:first-child{
                            margin-bottom: 4px;
                        }
                        &:nth-child(2){
                            margin-bottom: 20px;
                        }

                    }
                    .active {
                        color: #00b7bd;
                    }
                    .menuHover:hover{
                        color: #00b7bd;
                    }
                    a:hover{
                        text-decoration: none;
                    }
                    .menuItem{
                        color: $quaternary-color;
                    }
                    .bottom-divider{
                        border-top: 1px solid $primary-theme;
                        height: 20px;
                        padding: 0;
                    }
                }
            }

            .footer{
                text-align: center;
                color: $primary-white;
                font-size: $quaternary-font-size;
                position: fixed;
                width: 15%;
                min-width: 300px;
                bottom: 20px;
            }
            
        }

        .content{
            height: 97.5%;
            width: 100%;
            margin: 0px 10px 0px 10px;
            padding-top: 20px;
            min-height: 738px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .itinerary-content, .mmd-content{

                .tabsDiv{
                    background-color: $primary-white;
                    border-bottom: 1px solid $tertiary-green;
                    border-radius: 0 1em 0 0 ;
                }

                .itineraryDiv{
                    // padding: 0px 20px 0px 20px;
                    font-size: $primary-font-size;
                    background-color: $primary-white;
                    border-radius: 0 0 1em 1em;

                    .itineraryDetailsDiv{
                        padding: 20px 20px 20px 20px;
                        
                        .itineraryDetails{
                            display: flex;
                            margin-bottom: 10px;
                            
                            .itineraryData{
                                display: grid;
                            }
                        }

                        .metersLbl{
                            background-image: linear-gradient(to right, $tertiary-green 50%, rgba(255, 255, 255, 0) 50%);
                            background-position: top;
                            background-size: 30px 1px;
                            background-repeat: repeat-x;
                            color: $primary-green;
                            // border-top: 1px dashed $tertiary-green;
                            font-weight: bold;
                            padding-top: 20px;
                            margin-bottom: 10px;
                        }
                    }

                    .mainMetersDiv{
                        // max-height: 42vh;
                        overflow-y: auto;
                        overflow-x: hidden;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .loading-spinner{
                            text-align: center;
                        }

                        .metersDiv{
                            padding: 10px 20px 10px 20px;
                            border-bottom: 1px solid $tertiary-green;
    
                            &:hover{
                                background-color: $primary-selected;
                            }

                            .meterDetailsDiv{
                                display: flex;
    
                                .meterDetails{
                                    display: inline-block;
                                    line-height: 1.2;
                                    .meterData{
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    .paginationDiv{
                        margin-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
        }
   }
}

@media only screen and (max-width: 1600px) {
    .mainMetersDiv{
       max-height: 48vh !important;
  }
}

@media only screen and (max-width: 1430px) {
    .mainMetersDiv{
        max-height: 41vh !important;
    }
}

@media only screen and (max-width: 1290px) {
    .mainMetersDiv{
        max-height: 33vh !important;
    }
}

.modal {
    &-content {
        /**min-width:  968px;
        min-height: 692px;
        padding: 100px 105px;*/
        height: 100%;
        width: 100%;
        box-shadow: 0px 6px 10px #00000014;
        border: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 16px !important;
        align-items: center;
        justify-content: center;

        // .carouselImg{
        //     height:100%;
        //     width: 100%;
        // }
    }
    &-carousel {
        height:100%;
        width: 100%;
        // height: 450px;
        // width: 450px;
        // display: flex;
        // flex-direction: column;
        // text-align: center;
        // justify-content: center;
        // align-items: center;
        
        .noImageLogo{
            height: 90px;
            width: 62px;
        }
        
        .carouselImg{
            height:100%;
            width: 100%;
        }
    }

    &-text-wrapper {
        // min-width: 417px;
        height: 100%;
        width: 100%;
        // padding-top: 180px;
        text-align: center;

        p {
            // font-family: 'Open Sans', sans-serif;
            color: $secondary-color;
            font-size: $primary-font-size;
            // margin-bottom: 30px;
        }
    }

    &-text-wrapper-info{
        min-width: 417px;
        width: 100%;
        text-align: center;
        font-family: 'Open Sans', sans-serif;

        .title {
            font-size: 22px;
            font-weight: bold;
            color: $primary-color;
            margin-bottom: 20px;
        }
        .info {
            font-size: 15px;
            color: $primary-black;
            margin-bottom: 20px;
        }
    }

    &-btn {
        &-primary {
            background-color: $primary-color;
            color: $primary-white;
            border-color: $primary-color;
            max-width: 250px;
            border-radius: 20px;
            font-size: 15px;
            width: 100%;
            height: 40px;
            margin-bottom:10px;
        }
        &-primary:hover {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        }
        &-secondary {
            background-color: $primary-white;
            color: $primary-color;
            border-color: $primary-color;
            max-width: 250px;
            border-radius: 20px;
            font-size: 15px;
            width: 100%;
            height: 40px;
        }
        &-secondary:hover {
            background-color: $primary-color;
            color: $primary-white;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        }
    }
}
