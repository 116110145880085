.mid-center-col {
  align-items: center;
  justify-content: center;
  display: flex;
}

.mid-left-col {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.mid-right-col {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.top-center-col {
  align-items: flex-start;
  justify-content: center;
  display: flex;
}

.top-left-col {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
}

.top-right-col {
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
}

.bottom-center-col {
  align-items: flex-end;
  justify-content: center;
  display: flex;
}

.bottom-left-col {
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
}

.bottom-right-col {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

.text-center {
  text-align: center;
}

.mt-5em {
  margin-top: 5em;
}

.wrapper {
  height: 100vh;
}
.wrapper .main-panel {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-x: hidden;
}
.wrapper .main-panel::-webkit-scrollbar {
  display: none;
}
.wrapper .main-panel .sidebar {
  height: 100%;
  width: auto;
  min-height: calc(99vh - 10px);
  min-width: 300px;
  background-color: #171C8F;
}
.wrapper .main-panel .sidebar .sidebar-wrapper {
  height: 100%;
  width: 100%;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .hht-logo {
  height: auto;
  width: auto;
  padding-top: 48px;
  text-align: center;
  margin-bottom: 48px;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .hht-logo img {
  height: auto;
  width: auto;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav {
  display: block;
  margin: 0px 16px 0px 16px;
  cursor: pointer;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav li {
  height: 64px;
  padding: 16px 0 0 32px;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav li .link {
  outline: none;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav li:first-child {
  margin-bottom: 4px;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav li:nth-child(2) {
  margin-bottom: 20px;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav .active {
  color: #00b7bd;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav .menuHover:hover {
  color: #00b7bd;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav a:hover {
  text-decoration: none;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav .menuItem {
  color: #EFF7F5;
}
.wrapper .main-panel .sidebar .sidebar-wrapper .nav .bottom-divider {
  border-top: 1px solid #f7f7f7;
  height: 20px;
  padding: 0;
}
.wrapper .main-panel .sidebar .footer {
  text-align: center;
  color: #FFFFFF;
  font-size: 12px;
  position: fixed;
  width: 15%;
  min-width: 300px;
  bottom: 20px;
}
.wrapper .main-panel .content {
  height: 97.5%;
  width: 100%;
  margin: 0px 10px 0px 10px;
  padding-top: 20px;
  min-height: 738px;
  overflow-y: auto;
}
.wrapper .main-panel .content::-webkit-scrollbar {
  display: none;
}
.wrapper .main-panel .content .itinerary-content .tabsDiv, .wrapper .main-panel .content .mmd-content .tabsDiv {
  background-color: #FFFFFF;
  border-bottom: 1px solid red;
  border-radius: 0 1em 0 0;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv, .wrapper .main-panel .content .mmd-content .itineraryDiv {
  font-size: 16px;
  background-color: #FFFFFF;
  border-radius: 0 0 1em 1em;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .itineraryDetailsDiv, .wrapper .main-panel .content .mmd-content .itineraryDiv .itineraryDetailsDiv {
  padding: 20px 20px 20px 20px;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .itineraryDetailsDiv .itineraryDetails, .wrapper .main-panel .content .mmd-content .itineraryDiv .itineraryDetailsDiv .itineraryDetails {
  display: flex;
  margin-bottom: 10px;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .itineraryDetailsDiv .itineraryDetails .itineraryData, .wrapper .main-panel .content .mmd-content .itineraryDiv .itineraryDetailsDiv .itineraryDetails .itineraryData {
  display: grid;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .itineraryDetailsDiv .metersLbl, .wrapper .main-panel .content .mmd-content .itineraryDiv .itineraryDetailsDiv .metersLbl {
  background-image: linear-gradient(to right, red 50%, rgba(255, 255, 255, 0) 50%);
  background-position: top;
  background-size: 30px 1px;
  background-repeat: repeat-x;
  color: #2C906B;
  font-weight: bold;
  padding-top: 20px;
  margin-bottom: 10px;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .mainMetersDiv, .wrapper .main-panel .content .mmd-content .itineraryDiv .mainMetersDiv {
  overflow-y: auto;
  overflow-x: hidden;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .mainMetersDiv::-webkit-scrollbar, .wrapper .main-panel .content .mmd-content .itineraryDiv .mainMetersDiv::-webkit-scrollbar {
  display: none;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .mainMetersDiv .loading-spinner, .wrapper .main-panel .content .mmd-content .itineraryDiv .mainMetersDiv .loading-spinner {
  text-align: center;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .mainMetersDiv .metersDiv, .wrapper .main-panel .content .mmd-content .itineraryDiv .mainMetersDiv .metersDiv {
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid red;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .mainMetersDiv .metersDiv:hover, .wrapper .main-panel .content .mmd-content .itineraryDiv .mainMetersDiv .metersDiv:hover {
  background-color: #F7EAD3;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .mainMetersDiv .metersDiv .meterDetailsDiv, .wrapper .main-panel .content .mmd-content .itineraryDiv .mainMetersDiv .metersDiv .meterDetailsDiv {
  display: flex;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .mainMetersDiv .metersDiv .meterDetailsDiv .meterDetails, .wrapper .main-panel .content .mmd-content .itineraryDiv .mainMetersDiv .metersDiv .meterDetailsDiv .meterDetails {
  display: inline-block;
  line-height: 1.2;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .mainMetersDiv .metersDiv .meterDetailsDiv .meterDetails .meterData, .wrapper .main-panel .content .mmd-content .itineraryDiv .mainMetersDiv .metersDiv .meterDetailsDiv .meterDetails .meterData {
  display: block;
}
.wrapper .main-panel .content .itinerary-content .itineraryDiv .paginationDiv, .wrapper .main-panel .content .mmd-content .itineraryDiv .paginationDiv {
  margin-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1600px) {
  .mainMetersDiv {
    max-height: 48vh !important;
  }
}
@media only screen and (max-width: 1430px) {
  .mainMetersDiv {
    max-height: 41vh !important;
  }
}
@media only screen and (max-width: 1290px) {
  .mainMetersDiv {
    max-height: 33vh !important;
  }
}
.modal-content {
  /**min-width:  968px;
  min-height: 692px;
  padding: 100px 105px;*/
  height: 100%;
  width: 100%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.0784313725);
  border: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 16px !important;
  align-items: center;
  justify-content: center;
}
.modal-carousel {
  height: 100%;
  width: 100%;
}
.modal-carousel .noImageLogo {
  height: 90px;
  width: 62px;
}
.modal-carousel .carouselImg {
  height: 100%;
  width: 100%;
}
.modal-text-wrapper {
  height: 100%;
  width: 100%;
  text-align: center;
}
.modal-text-wrapper p {
  color: #3B3B3B;
  font-size: 16px;
}
.modal-text-wrapper-info {
  min-width: 417px;
  width: 100%;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}
.modal-text-wrapper-info .title {
  font-size: 22px;
  font-weight: bold;
  color: #919191;
  margin-bottom: 20px;
}
.modal-text-wrapper-info .info {
  font-size: 15px;
  color: #3B3B3B;
  margin-bottom: 20px;
}
.modal-btn-primary {
  background-color: #919191;
  color: #FFFFFF;
  border-color: #919191;
  max-width: 250px;
  border-radius: 20px;
  font-size: 15px;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}
.modal-btn-primary:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
.modal-btn-secondary {
  background-color: #FFFFFF;
  color: #919191;
  border-color: #919191;
  max-width: 250px;
  border-radius: 20px;
  font-size: 15px;
  width: 100%;
  height: 40px;
}
.modal-btn-secondary:hover {
  background-color: #919191;
  color: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.primary-box {
  height: 7em;
}

.secondary-box {
  height: 5em;
}

.tertiary-box {
  height: 2em;
}

.primary-font-size {
  font-size: 16px !important;
}

.secondary-font-size {
  font-size: 20px;
}

.tertiary-font-size {
  font-size: 32px;
}

.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}

.primary-bg-color {
  background-color: #f7f7f7;
}

.primary-color {
  color: #919191;
}

.secondary-color {
  color: #3B3B3B;
}

.tertiary-color {
  color: #F88FAA;
}

.primary-color-white {
  color: #FFFFFF !important;
}

.primary-color-black {
  color: #3B3B3B;
}

.logo {
  height: 35px;
}

.logo-normal {
  font-size: 26px;
}

.primary-font-size {
  font-size: 16px;
}

.secondary-font-size {
  font-size: 20px;
}

.sidebar-icon {
  margin-right: 10px;
  padding-bottom: 3px;
}

.sidebar-item {
  margin-bottom: 0.333em;
}
.sidebar-item:hover {
  background-color: rgba(59, 59, 59, 0.5019607843);
}

.activeUser {
  padding: 10px 0px 30px 0px;
}

.logout {
  margin-left: 1.56em;
  margin-right: 1.56em;
  border-top: 1px solid #f7f7f7;
}

.header {
  font-size: 32px;
  color: #111111;
  font-weight: lighter;
}

.meterLbl {
  color: #919191;
}

.meterData {
  color: #3B3B3B;
  margin-bottom: 10px;
}

.imgLbl {
  color: #2C906B;
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
}

.imgLblD {
  color: #919191;
  display: block;
  margin-bottom: 10px;
}

.longLat {
  color: #2C906B;
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
}
.longLat .updateLbl {
  color: #F88FAA;
}

.passwordCriteriaInvalid {
  margin-bottom: 0px;
  font-style: italic;
  font-size: 10px !important;
  color: red;
}

.passwordCriteriaValid {
  margin-bottom: 0px;
  font-style: italic;
  font-size: 10px !important;
  color: green;
}

.paginationBtn .ngx-pagination .current {
  background: #2C906B;
  border-radius: 50%;
}
.paginationBtn .ngx-pagination li {
  background: #f7f7f7;
  border-radius: 50%;
}
.paginationBtn .ngx-pagination .pagination-previous, .paginationBtn .ngx-pagination .pagination-next {
  background: none;
  color: #f7f7f7;
}

.ngb-pagination li:first-child a {
  color: red;
  background-color: #FFFFFF;
  border: none;
  box-shadow: none;
}
.ngb-pagination li:first-child a span {
  display: none;
}
.ngb-pagination li:first-child a:before {
  content: "First";
}
.ngb-pagination li:first-child a:hover {
  background-color: #FFFFFF;
  color: #2C906B;
}
.ngb-pagination li:first-child a:focus {
  box-shadow: none;
}
.ngb-pagination li:nth-child(2) a {
  color: red;
  background-color: #FFFFFF;
  border: none;
  box-shadow: none;
}
.ngb-pagination li:nth-child(2) a span {
  display: none;
}
.ngb-pagination li:nth-child(2) a:before {
  content: "Previous";
}
.ngb-pagination li:nth-child(2) a:hover {
  background-color: #FFFFFF;
  color: #2C906B;
}
.ngb-pagination li:nth-child(2) a:focus {
  box-shadow: none;
}
.ngb-pagination li:last-child a {
  color: red;
  background-color: #FFFFFF;
  border: none;
  box-shadow: none;
}
.ngb-pagination li:last-child a span {
  display: none;
}
.ngb-pagination li:last-child a:before {
  content: "Last";
}
.ngb-pagination li:last-child a:hover {
  background-color: #FFFFFF;
  color: #2C906B;
}
.ngb-pagination li:last-child a:focus {
  box-shadow: none;
}
.ngb-pagination li:nth-last-child(2) a {
  color: red;
  background-color: #FFFFFF;
  border: none;
  box-shadow: none;
}
.ngb-pagination li:nth-last-child(2) a span {
  display: none;
}
.ngb-pagination li:nth-last-child(2) a:before {
  content: "Next";
}
.ngb-pagination li:nth-last-child(2) a:hover {
  background-color: #FFFFFF;
  color: #2C906B;
}
.ngb-pagination li:nth-last-child(2) a:active {
  box-shadow: none;
  border-color: transparent;
}
.ngb-pagination li:nth-last-child(2) a:focus {
  box-shadow: none;
}
.ngb-pagination li:not(:first-child):not(:last-child):not(:nth-child(2)):not(:nth-last-child(2)) a {
  height: 27px;
  width: 27px;
  margin-right: 5px;
  margin-top: 5px;
  justify-content: center;
  border-radius: 100%;
  color: red;
  border-color: transparent;
  background-color: #FFFFFF;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.ngb-pagination li:not(:first-child):not(:last-child):not(:nth-child(2)):not(:nth-last-child(2)) a:hover {
  background-color: red;
  color: #FFFFFF;
}
.ngb-pagination li:not(:first-child):not(:last-child):not(:nth-child(2)):not(:nth-last-child(2)).active a {
  background-color: #2C906B;
  color: #FFFFFF;
  box-shadow: none;
}
.ngb-pagination .page-item.disabled:not(:first-child):not(:nth-child(2)):not(:last-child):not(:nth-last-child(2)) {
  display: none;
}
.ngb-pagination .page-item.disabled a:before {
  color: #f7f7f7;
}

.filterMetersIcon {
  margin-left: 10px;
  cursor: pointer;
}

input:focus {
  outline: none;
}

.searchBar {
  margin: 20px 0px 20px 20px;
}
.searchBar .searchItinerary {
  background: url("../../../img/icons/icon-search.svg") no-repeat scroll 30px 19px;
  height: 60px;
  width: 380px;
  padding: 20px 0px 20px 60px;
  border: 1px solid red;
  border-radius: 1.875em;
  background-color: #FFFFFF !important;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
}

.mmdSearchBar {
  margin: 20px 0px 20px 0px;
}
.mmdSearchBar .searchItinerary {
  background: url("../../../img/icons/icon-search.svg") no-repeat scroll 30px 19px;
  height: 60px;
  width: 380px;
  padding: 20px 0px 20px 60px;
  border: 1px solid red;
  border-radius: 1.875em;
  background-color: #FFFFFF !important;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
}

.mmdTextBox {
  margin: 20px 0px 20px 0px;
}
.mmdTextBox .txtBox {
  height: 30px;
  width: 380px;
  padding: 20px 0px 20px 60px;
  border: 1px solid red;
  border-radius: 1.875em;
  background-color: #FFFFFF !important;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
}

::placeholder {
  color: #aaaaaa;
}

.dropdownDiv {
  width: 100%;
}
.dropdownDiv .selectMonth, .dropdownDiv .selectYear {
  height: 60px;
  width: 183px;
  margin: 0px 10px 10px 0px;
  padding-left: 20px;
  border-color: red;
  border-radius: 1.875em;
  font-size: 20px;
  color: #2C906B;
  outline: none;
  background: url("../../../img/icons/icon-green-caret-down.svg") no-repeat center;
  background-color: #FFFFFF;
  background-position-x: 90%;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.filterDiv {
  float: right;
}
.filterDiv span {
  color: #2C906B;
  font-size: 20px;
}
.filterDiv .selectFilter {
  height: 60px;
  width: 183px;
  margin-left: 10px;
  padding: 0px 15px 0px 10px;
  border-color: red;
  border-radius: 1.875em;
  font-size: 20px;
  color: #2C906B;
  outline: none;
  background: url("../../../img/icons/icon-green-caret-down.svg") no-repeat center;
  background-color: #FFFFFF;
  background-position-x: 95%;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.itineraryTbl, .mmdTbl {
  min-height: calc(80vh - 10px);
  height: 100%;
  width: 100%;
  margin-top: 10px;
  background-color: #FFFFFF;
  border-radius: 1em;
  overflow-x: auto;
}
.itineraryTbl table, .mmdTbl table {
  height: 1vh;
  width: 100%;
  table-layout: fixed;
  cursor: pointer;
}
.itineraryTbl table thead, .mmdTbl table thead {
  border-bottom: 1px solid red;
  font-size: 16px;
  color: #2C906B;
}
.itineraryTbl table thead tr th, .mmdTbl table thead tr th {
  height: 60px;
  width: 194px;
  padding-left: 20px;
  color: #2C906B;
  font-weight: normal;
}
.itineraryTbl table thead tr th span, .mmdTbl table thead tr th span {
  padding-left: 5px;
}
.itineraryTbl table thead tr th .sorted, .mmdTbl table thead tr th .sorted {
  color: #EBCA90;
}
.itineraryTbl table thead tr th .no-sort, .mmdTbl table thead tr th .no-sort {
  padding-left: 17px;
}
.itineraryTbl table tbody, .mmdTbl table tbody {
  font-size: 16px;
  color: #3B3B3B;
}
.itineraryTbl table tbody .table-no-data, .mmdTbl table tbody .table-no-data {
  color: #919191;
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.itineraryTbl table tbody tr td, .mmdTbl table tbody tr td {
  height: 60px;
  width: 20%;
  padding-left: 37px;
  white-space: normal;
  word-wrap: break-word;
}
.itineraryTbl table tbody tr .upd-loc, .mmdTbl table tbody tr .upd-loc {
  color: #F88FAA;
}
.itineraryTbl table tbody tr:hover, .mmdTbl table tbody tr:hover {
  background-color: #F7EAD3;
}
.itineraryTbl table tfoot tr td, .mmdTbl table tfoot tr td {
  padding-left: 27px;
}

.homepage-container {
  min-height: calc(80vh - 10px);
  height: 100%;
  width: 100%;
  margin-top: 8px;
  background-color: #FFFFFF;
  border-radius: 1em;
  overflow-x: auto;
}