// .font-bold{
//     font-weight: bold;
// }

// .font-semibold{
//     font-weight: 600;
// }

// .font-italic {
//     font-style: italic;
// }

// .font-underline {
//     text-decoration: underline;
// }

// .font-line-through {
//     text-decoration: line-through;
// }

// .font-overline {
//     text-decoration: overline;
// }

// .header-placeholder{
//     font-size: 22px;
//     color:$primary-color;
// }

// .placeholder{
//     font-size: $primary-font-size;
//     color:$primary-color;
// }

// .header-cbmCrumbs{
//     color:$primary-color;
//     opacity: 60%;
//     cursor: pointer;
// }
// .header-cbmName{
//     color:$primary-color;
// }

// span.label{
//     font-size:$secondary-font-size;
//     color: $secondary-color;
// }

// span.info, span.data{
//     text-transform: capitalize;
//     font-size:$secondary-font-size;
//     color: $primary-black;
// }

// span.remarks{
//     font-size:$secondary-font-size;
//     color: $primary-black;
// }

.header{
    font-size: $tertiary-font-size;
    color: #111111;
    font-weight: lighter;
}

.meterLbl{
    color: $primary-color;
}

.meterData{
    color: $secondary-color;
    margin-bottom: 10px;
}

.imgLbl{
    color: $primary-green;
    cursor: pointer;
    display: block;
    margin-bottom: 10px;

}

.imgLblD{
    color: $primary-color;
    display: block;
    margin-bottom: 10px;
}

.longLat{
    color: $primary-green;
    cursor: pointer;
    display: block;
    margin-bottom: 10px;

    .updateLbl{
        color: $tertiary-color;
    }
}

.passwordCriteriaInvalid{
    margin-bottom: 0px;
    font-style: italic;
    font-size: 10px!important; 
    color: red;
}
.passwordCriteriaValid{
    margin-bottom: 0px;
    font-style: italic;
    font-size: 10px!important; 
    color: green;
}
