// select   {
//     width: 100%;
//     padding: $padding;
//     border: solid 1px $primary-theme;

//     -moz-appearance:none; /* Firefox */
//     -webkit-appearance:none; /* Safari and Chrome */
//     appearance:none;

//     background: url("assets/img/caret.png") no-repeat center;
//     background-color: $primary-white;
//     background-size: 1em;
//     background-position-x: 96%;
//     outline: none;
//     margin: 0.33em 0em 0.33em 0em;
// }

// select:disabled{
//     border: solid 1px $tertiary-white !important;
//     border-radius: 2em;
//     background-color: $tertiary-white;
//     color: $primary-black;
//     font-family: 'Open Sans', sans-serif !important;
// }

// select:focus {
//     border: solid 1px $primary-color !important;
// }

// option[value=""][disabled] {
//     display: none;
// }

// option:checked {
//     background-color: $primary-color;
//     color: $primary-white;
// }

.dropdownDiv{
    width:100%;

    .selectMonth, .selectYear{
        height: 60px;
        width: 183px;
        margin: 0px 10px 10px 0px;
        padding-left: 20px;
        border-color: $tertiary-green;
        border-radius: 1.875em;
        font-size: $secondary-font-size;
        color: $primary-green;
        outline: none;
        background: url("../../../img/icons/icon-green-caret-down.svg") no-repeat center;
        background-color: $primary-white;
        background-position-x: 90%;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}

.filterDiv{
    float: right;
    span{
        color: $primary-green;
        font-size: $secondary-font-size;
    }

    .selectFilter{
        height: 60px;
        width: 183px;
        margin-left: 10px;
        padding: 0px 15px 0px 10px;
        border-color: $tertiary-green;
        border-radius: 1.875em;
        font-size: $secondary-font-size;
        color: $primary-green;
        outline: none;
        background: url("../../../img/icons/icon-green-caret-down.svg") no-repeat center;
        background-color: $primary-white;
        background-position-x: 95%;
        -webkit-appearance: none;
        -moz-appearance: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

}