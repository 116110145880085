// vertical-horizontal-(col/row)
.mid-center-col {
    align-items: center;
    justify-content: center;
    display: flex;
}

.mid-left-col {
    align-items: center;
    justify-content: flex-start;
    display: flex;
}

.mid-right-col {
    align-items: center;
    justify-content: flex-end;
    display: flex;
}

.top-center-col {
    align-items: flex-start;
    justify-content: center;
    display: flex;
}

.top-left-col {
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
}

.top-right-col {
    align-items: flex-start;
    justify-content: flex-end;
    display: flex;
}

.bottom-center-col {
    align-items: flex-end;
    justify-content: center;
    display: flex;
}

.bottom-left-col {
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
}

.bottom-right-col {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
}

.text-center {
    text-align: center;
}

// MARGINS
.mt-5em{
    margin-top: 5em;
}