// THEME COLOR
.primary-bg-color{
    background-color: $primary-theme;
}

.primary-color{
    color: $primary-color;
}

.secondary-color{
    color: $secondary-color;
}

.tertiary-color{
    color: $tertiary-color;
}

// COLOR WHITE
.primary-color-white{
    color: $primary-white !important;
}

// COLOR BLACK
.primary-color-black{
    color: $primary-black;
}

//Logo
.logo{
    height: 35px;
}

.logo-normal{
    font-size: 26px;
}

.primary-font-size{
    font-size: $primary-font-size;
}

.secondary-font-size{
    font-size: $secondary-font-size;
}

//icons
.sidebar-icon{
    margin-right:10px;
    padding-bottom: 3px;
}

//items
.sidebar-item{
    margin-bottom: 0.333em;

    &:hover{
        background-color: $secondary-gray;
    }
}

//Font family
// .sidebar-wrapper{
//     font-family: 'Open Sans', sans-serif;
// }

//user border
.activeUser{
    padding: 10px 0px 30px 0px;
}

//Menu item
// .menuItem{
//     text-transform: capitalize;
// }

//logout border
.logout{
    margin-left: 1.56em;
    margin-right: 1.56em;
    border-top: 1px solid $primary-theme;
}

//dividers
// .top-divider{
//     margin-left: 1.56em;
//     margin-right: 1.56em;
//     border-bottom: 1px solid $primary-color;
// }
// .bottom-divider{
//     margin-left: 1.56em;
//     margin-right: 1.56em;
//     border-top: 1px solid $primary-theme;
// }